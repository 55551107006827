/* eslint-disable no-console */
import React, { useContext, useEffect, useState, useMemo } from 'react';
// import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { useLocation } from 'react-router-dom';

import getI18nService from '../../i18n/i18n';
import { Context } from '../../store/Store';
import { getStorageItem } from '../../common/Storage';
import Icons from '../../common/Icons';
import userService from '../../api/UserService';

const i18n = getI18nService();
function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
const wired = 'wired';
const { RJ45 } = Icons;
const RequestSuccessful = () => {
  const [state, dispatch] = useContext(Context);
  const [tenantName, setTenantName] = useState('-');
  const [message, setMessage] = useState(i18n.t('Messages.sponsoredAccessGranted'));
  const { api, emailData } = state;
  const portalData = api['get_portal'];
  let query = useQuery();
  const deviceType = query.get('deviceType');

  useEffect(() => {
    let tName = getStorageItem('tanantName');
    if (portalData) {
      tName = portalData.tenantName;
    }
    let eData = getStorageItem(userService.UserLogin.name);
    if (emailData) {
      eData = emailData;
    }
    setTenantName(tName);
    if (deviceType && deviceType === wired) {
      setMessage(i18n.t('Messages.wiredSsoAccessGranted'));
    }
  }, []);

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: 4,
        m: 2,
        mt: 10,
        boxShadow: 'rgb(0 0 0 / 25%) 2px 4px 10px 4px',
        border: '1px solid rgb(81, 81, 81)',
      }}
    >
      <Box
        sx={{
          mx: 'auto',
          m: 4,
        }}
      >
        {tenantName && <Box sx={{ textAlign: 'center', p: 1, fontSize: 'h4.fontSize' }}>{tenantName}</Box>}
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Box
            sx={{
              textAlign: 'center',
              fontSize: 'h6.fontSize',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {message}
          </Box>
          {deviceType === wired && (
            <Box paddingTop={4}>
              <RJ45 width={70} height={70} color="#0DFDD2" />
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

RequestSuccessful.propTypes = {};

export default RequestSuccessful;
